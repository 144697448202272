










































export default

	props: block: Object

	computed:
	 	# Is the asset a GIF?
		isGIF: -> @asset?.mimeType == 'image/gif'

		# Ease of access
		asset: -> @block.asset?[0]

		# Make gif url
		GIFSrc: ->
			imgixUrl = process.env.IMGIX_URL
			if imgixUrl then "#{imgixUrl}/#{@asset.path}"
			else @asset.url

